// Update your JSX to include the CSS classes
import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function PrivacyPolicy() {
    return (
        <div className="background-image">
            <div className="TermsAndConditions">
                <h1>Privacy Policy</h1>
                <p>
                    <strong>Effective Date:</strong> January 2024
                    <br />
                </p>
                <h4>1. Information We Collect</h4>
                <p>
                    We may collect two types of information: personal information you provide directly and information automatically collected through your use of the app.
                </p>
                <h3>A. Information You Provide</h3>
                <p>
                    Account Information: When you create an account, we may ask for your name, email address, username, and password.
                </p>
                <p>
                    Profile Details: You can optionally add a profile picture, bio, location, and other details to personalize your experience.
                </p>
                <p>
                    Content You Share: Any photos, descriptions, or posts you upload to showcase your sneaker collection or participate in the community.
                </p>
                <p>
                    Marketplace Transactions (if applicable): If you engage in buying, selling, or trading sneakers via the marketplace, we may collect payment information, shipping addresses, and transaction details.
                </p>
                <h3>B. Automatically Collected Information</h3>
                <p>Device Information: We may collect technical details such as device type, operating system, IP address, and unique identifiers.</p>
                <p></p>
                <h4>2. How We Use Your Information</h4>
                <p>We use the information we collect to:</p>
                <p>Provide and improve our services.</p>
                <p>Process transactions if you use the marketplace feature.</p>
                <p>Communicate with you regarding updates, promotions, or important announcements.</p>
                <p>Ensure security and prevent fraud.</p>
                <p>Conduct research and analysis to better understand user behavior and preferences.</p>

                <h4>3. Sharing Your Information</h4>
                <p>We do not sell your personal information. However, we may share it under the following circumstances:</p>
                <h3>With Your Consent: </h3>
                <p>For example, when you choose to share your posts publicly or connect with other users.</p>
                <h3>Service Providers:</h3>
                <p>We work with trusted third-party partners who assist us in operating the app, processing payments, analyzing data, or providing customer support. These partners are bound by confidentiality agreements.</p>
                <h3>Legal Requirements:</h3>
                <p>We may disclose information if required by law, to comply with legal processes, or to protect the rights, property, or safety of FeetDrip, its users, or others.</p>

                <h4>4. Your Rights</h4>
                <p>You have control over your data:</p>
                <p>Access and Update: You can view, edit, or delete your profile information at any time within the app settings.</p>
                <p>Deletion Request: To request deletion of your account and associated data, contact us using the methods provided below.</p>

                <h4>5. Changes to This Privacy Policy</h4>
                <p>We reserve the right to update this Privacy Policy periodically. Any changes will be posted here, and we may notify you via email or within the app. Continued use of FeetDrip after updates indicates your acceptance of the revised policy.</p>

                <h4>6. Contact Information</h4>
                <p>
                    If you have questions, concerns, or requests regarding this Privacy Policy or your data, please contact us at:

                    <br />
                    FEETDRIP
                    <br />
                    feetdrip000@proton.me
                    <br />
                </p>
                <Link to="/">Back to homepage <br /></Link>
                <br />
            </div>
        </div >
    );
}

export default PrivacyPolicy;