import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function HomePage() {
  return (
    <div className="background-image">
      <div className="home-page-text">
        <h1>FeetDrip</h1>
        <p>The best AI generated sneaker models catalogue.</p>
        <p>more to come...</p>
        
        <br />
        <div>
        <Link to="/terms-and-conditions">View Terms and Conditions</Link>
        <Link to="/privacy-policy">View Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;